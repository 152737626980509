.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@import "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700";


body {
  /*font-family: 'Poppins', sans-serif;*/
  font-family: 'Nunito Sans', sans-serif;
  /*background: #fafafa;*/
  background-color: #f5f9fc;
}


a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #354052;
  color: #adb5bd;
  transition: all 0.5s;  
}

.sidebar.active {
  margin-left: -250px;
  transition: .5s;
}

.sidebar-header {
  background: #313b4c;
  color: #adb5bd;
}

.sidebar-header h3{
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.content {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
}

.collapse.show, 
.collapsing {
  background: #313b4c;
}

.nav-item {
  /*color: #ced4da;*/
  color: #adb5bd;
  font-weight: 300;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}

.nav-item.active {
  color: #e9ecef;
  background: #2d3646 !important;
}

.nav-item:hover {
  background: #313b4c;
  color: #fff;
  transition: .2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

.nav-link {
  color: #adb5bd;
}

.nav-link:hover {
  background: #313b4c;
  color: #fff;
  transition: .2s;
}

.label-error {
  color: #f00;
}

label.label-error + input {
  border: 1px solid red;
  background-color:rgba(255,0,0,0.05);
}

label.label-error + input:focus {
  border: 1px solid red;
  background-color:rgba(255,0,0,0.05);
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  height: 100vh;
  padding: 20px;
  margin-left: 0;
  overflow: scroll;
}

@media only screen and (max-width: 768px) {
  body {
    overflow:  hidden;
  }
  
  .sidebar {
    margin-left: -250px;
  }
  
  .sidebar.active {
    margin-left: 0;
  }
  
  .sidebar.active > .sidebar-header button {
    display: block;
  }
  
  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
  
}